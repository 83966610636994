<template>
  <div>
    <div v-permission="['adminextcrm',]">
      <div class="row">
        <div class="col-md-12">
          <KTPortlet
              v-bind="{
            }"
          >
            <template v-slot:body>
              <b-button-toolbar
                  aria-label="Toolbar with button groups and input groups"
              >
                <b-button-group size="sm" class="mr-1">
                  <b-button v-permission="'adminextcrm'" :to="{name:'ext-crm-list'}" class="mr-2 btn-info">Back to list</b-button>
                  <b-button v-permission="'adminextcrmwrite'" v-on:click="resetJob" class="mr-2 btn-warning">Reset Job</b-button>
                  <b-button v-permission="'adminextcrm'" v-on:click="openVideo" class="mr-2 btn-primary">Open Video</b-button>
                  <b-button v-permission="'adminextcrm'" v-on:click="downloadZip" class="mr-2 btn-primary">Download Zip</b-button>
                </b-button-group>
              </b-button-toolbar>
            </template>
          </KTPortlet>
        </div>

      </div>
    </div>
    <KTPortlet
        v-bind="{
    }"
        v-if="form"
        v-permission="['adminextcrm',]"
        v-bind:class="{ 'kt-spinner-f': loading }"
    >
      <template v-slot:title>
        <h4 class="">{{ 'Informazioni Job' }}</h4>
      </template>
      <template v-slot:body>
        <div class="row">
          <div class="col-md-6">
            <table class="table-info withwrap">
              <tbody>
              <tr v-if="form.id">
                <td>ID</td>
                <td>{{form.id}}</td>
              </tr>
              <tr v-if="form.contract_log_id">
                <td>Contract log id</td>
                <td>{{form.contract_log_id}}</td>
              </tr>
              <tr v-if="form.instant_call_provider">
                <td>Provider Instant Call</td>
                <td>{{form.instant_call_provider}}</td>
              </tr>
<!--              <tr v-if="form.eva_payload">-->
<!--                <td>Payload EVA</td>-->
<!--                <td>{{form.eva_payload}}</td>-->
<!--              </tr>-->
              <tr v-if="form.instant_call_payload">
                <td>Payload Instant Call</td>
                <td>{{form.instant_call_payload}}</td>
              </tr>
              <tr v-if="form.note">
                <td>Note</td>
                <td>{{form.note}}</td>
              </tr>
              <tr v-if="form.status">
                <td>Stato</td>
                <td>{{form.status}}</td>
              </tr>
              <tr v-if="form.eva_status">
                <td>Stato EVA</td>
                <td>{{form.eva_status}}</td>
              </tr>
              <tr v-if="form.eva_failures">
                <td>Failures EVA</td>
                <td>{{form.eva_failures}}</td>
              </tr>
              <tr v-if="form.eva_error">
                <td>Errore EVA</td>
                <td>{{form.eva_error}}</td>
              </tr>
              <tr v-if="form.failures">
                <td>Failures</td>
                <td>{{form.failures}}</td>
              </tr>
              <tr v-if="form.sent_at">
                <td>Data invio</td>
                <td>{{form.sent_at | formatDate}}</td>
              </tr>
              <tr v-if="form.eva_sent_at">
                <td>Data invio a EVA</td>
                <td>{{form.eva_sent_at | formatDate}}</td>
              </tr>
              <tr v-if="form.callback_at">
                <td>Data Callback</td>
                <td>{{form.callback_at | formatDate}}</td>
              </tr>
              <tr v-if="form.created_at">
                <td>Data creazione</td>
                <td>{{form.created_at | formatDate}}</td>
              </tr>
              <tr v-if="form.updated_at">
                <td>Data aggiornamento</td>
                <td>{{form.updated_at | formatDate}}</td>
              </tr>
              <tr v-if="form.codice_agente">
                <td>Codice Agente</td>
                <td>{{form.codice_agente}}</td>
              </tr>
              <tr v-if="form.codice_subagenzia">
                <td>Codice Subagenzia</td>
                <td>{{form.codice_subagenzia}}</td>
              </tr>
              <tr v-if="form.codice_societa">
                <td>Codice Società</td>
                <td>{{form.codice_societa}}</td>
              </tr>
              <tr v-if="form.random_code">
                <td>Codice Random</td>
                <td>{{form.random_code}}</td>
              </tr>
              <tr v-if="form.bot_status">
                <td>Stato Bot</td>
                <td>{{form.bot_status}}</td>
              </tr>
              <tr v-if="form.bot_reserved_at">
                <td>Data Riserva Bot</td>
                <td>{{form.bot_reserved_at | formatDate}}</td>
              </tr>
              <tr v-if="form.bot_done_at">
                <td>Data Completamento Bot</td>
                <td>{{form.bot_done_at | formatDate}}</td>
              </tr>
              <tr v-if="form.bot_failed_at">
                <td>Data Fallimento Bot</td>
                <td>{{form.bot_failed_at | formatDate}}</td>
              </tr>
              <tr v-if="form.last_bot_error">
                <td>Ultimo Errore Bot</td>
                <td>{{form.last_bot_error}}</td>
              </tr>
              <tr v-if="form.bot_meta">
                <td>Meta Bot</td>
                <td>{{form.bot_meta}}</td>
              </tr>

              </tbody>
            </table>
          </div>
          <div class="col-md-6">
            <!-- Include other content here, like maps or additional info if needed -->
          </div>
        </div>
      </template>
    </KTPortlet>


    <div class="row" v-permission="['adminextcrmwrite']">
      <div class="col-md-12">



        <KTPortlet
            v-bind="{
            }"
            v-bind:class="{ 'kt-spinner-f': loading }"
        >
          <template v-slot:title>
            <h4 class="">Stato EVA</h4>
          </template>
          <template v-slot:body>
            <b-form @submit="updateStatus" @reset="onReset">
              <b-form-group
                  label-cols="4"
                  label-cols-lg="2"
                  label="EVA status"
                  label-for="label"
              >
                <b-form-select
                    class="mb-2 mr-sm-2 mb-sm-0"
                    v-model="formUpdateStatus.status"
                    :options="statuseva"
                    id="inline-form-custom-select-pref"
                    required
                >
                  <template v-slot:first>
                    <option :value="null">-</option>
                  </template>
                </b-form-select>
              </b-form-group>
              <b-form-group
                  label-cols="4"
                  label-cols-lg="2"
                  label="EVA Error"
                  label-for="label"
              >
                <b-form-input
                    id="label"
                    v-model="formUpdateStatus.error"
                    type="text"
                    placeholder=""
                ></b-form-input>
              </b-form-group>
              <b-button class="pull-right" type="submit" variant="primary">Salva</b-button>
            </b-form>
          </template>
        </KTPortlet>
        <!--end:: Widgets/Activity-->
      </div>

    </div>

    <div class="row" v-permission="['adminextcrmwrite']">
      <div class="col-md-12">



        <KTPortlet
            v-bind="{
            }"
            v-bind:class="{ 'kt-spinner-f': loading }"
        >
          <template v-slot:title>
            <h4 class="">Configurazione</h4>
          </template>
          <template v-slot:body>


            <div class="json_editor" id="estra_editor"></div>


            <b-form @submit="onSubmit" @reset="onReset">



              <b-button class="pull-right" type="submit" variant="primary">Salva</b-button>
              <b-button class="pull-right mx-4" v-on:click="saveAndResetJob"  variant="warning">Salva e resetta</b-button>
              <b-button type="reset" variant="danger">Indietro</b-button>

            </b-form>
          </template>
        </KTPortlet>
        <!--end:: Widgets/Activity-->
      </div>

    </div>







    <b-modal :hide-footer="true" ref="video-modal"  size="xl"  title="Video recording">
      <video v-if="videoRecording" width="100%" controls autoplay>
        <source :src="videoRecording" type="video/mp4">
      </video>
    </b-modal>



  </div>
</template>

<script>
import {SET_BREADCRUMB} from "../../../store/breadcrumbs.module";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import UserService from "../../../common/user.service";
import ApiService from "../../../common/api.service";
import Vue from "vue";
import { JSONEditor } from '@json-editor/json-editor';
// import * as JSONEditor from '@json-editor/json-editor';
// import { JSONEditor } from '@json-editor/json-editor/dist/jsoneditor.js'
import schema_eva_payload from '../../../assets/schema/eva_payload.json';
import UtilService from "@/common/util.service";

export default {
  data() {
    return {
      loading:false,
      change_pwd:false,
      newpasswordtype:'password',
      formoptions:{
      },
      form: {
      },
      editor:null,
      videoRecording:false,
      formUpdateStatus:{
        status:null
      },
      statuseva:[
        {
          value:'READY',
          text:'READY',
        },
        {
          value:'FAILED',
          text:'FAILED',
        },
        {
          value:'SENT',
          text:'SENT',
        },
        {
          value:'IGNORED',
          text:'IGNORED',
        },
        {
          value:'NON INSERITO',
          text:'NON INSERITO',
        },
        {
          value:'INSERITO CON ATTIVATORE',
          text:'INSERITO CON ATTIVATORE',
        },
        {
          value:'RESPINTO',
          text:'RESPINTO',
        },
        {
          value:'RESPINTO DEFINITIVO',
          text:'RESPINTO DEFINITIVO',
        },
        {
          value:'IN GESTIONE CONTRATTO DI DESTINAZIONE',
          text:'IN GESTIONE CONTRATTO DI DESTINAZIONE',
        }
      ]
    }
  },
  components: {
    KTPortlet
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "CRM Esterni", route: "/ext-crm/list" },
      { title: 'Modifica job' }
    ]);


    // https://github.com/json-editor/json-editor
    this.editor =  new JSONEditor(document.getElementById('estra_editor'),{
      schema: schema_eva_payload,
      prompt_before_delete: true,
      no_additional_properties: true,
      theme:'bootstrap4'
    });


  },

  created () {
    this.initialize()
  },
  methods:{
    currentEditId: function(){
      return this.$router.currentRoute.params.id;
    },
    onSubmit(evt) {
      evt.preventDefault();
      this.saveJob(false);
    },
    updateStatus(){
      const datasend = {
        eva_status: this.formUpdateStatus.status,
        eva_error: this.formUpdateStatus.error
      }
      ApiService.put('bot-management/jobs/eva/'+this.currentEditId(),datasend)
          .then(({data}) => {
            Vue.customNotifySuccess('Salvato');
            this.loading = false;
            this.reload();
            UtilService.scrollToTop();

          })
          .catch(({response}) => {
            if(response.data && response.data.errors) {
              Vue.customNotifyError('Errore salvataggio', response.data && response.data.errors ? response.data.errors.join(', ') : 'Si prega di riprovare');
            }else{
              Vue.customNotifyError('Errore salvataggio', response.data ? JSON.stringify(response.data) : 'Si prega di riprovare');
            }
            this.reload();
          });
    },
    openVideo() {
      ApiService.get('bot-management/jobs/download/'+this.currentEditId()+'/video')
          .then(({data}) => {
            // Vue.customNotifySuccess('Salvato');
            this.loading = false;
            this.videoRecording = data.url;
            this.$refs['video-modal'].show();
          })
          .catch((error) => {
            Vue.customNotifyError('Errore apertura', error.response && error.response.data ? JSON.stringify(error.response.data) : 'Si prega di riprovare');
            this.loading = false;
            // this.reload();
          });
    },
    downloadZip(){
      ApiService.get('bot-management/jobs/download/'+this.currentEditId()+'/zip')
          .then(({data}) => {
            // Vue.customNotifySuccess('Salvato');
            this.loading = false;
            UtilService.downloadUrl(data.url);
          })
          .catch((error) => {
            Vue.customNotifyError('Errore download', error.response && error.response.data ? JSON.stringify(error.response.data) : 'Si prega di riprovare');
            this.loading = false;
            // this.reload();
          });
    },
    saveJob(resetJob) {
      //alert(JSON.stringify(this.form))
      this.loading=true;

      // Validate the editor's current value against the schema
      const errors = this.editor.validate();

      if (errors.length) {
        // errors is an array of objects, each with a `path`, `property`, and `message` parameter
        // `property` is the schema keyword that triggered the validation error (e.g. "minLength")
        // `path` is a dot separated path into the JSON object (e.g. "root.path.to.field")
        // console.log(errors);
        Vue.customNotifyError('Errore',errors.map(e=>e.message).join(', '));
        this.loading=false;
      }
      else {
        // It's valid!
      }

      // Custom non schema validations
      const editorData = this.editor.getValue();
      // console.log(editorData);
      if((!editorData.siti_elettrici||!editorData.siti_elettrici.length)&&(!editorData.siti_gas||!editorData.siti_gas.length)){
        Vue.customNotifyError('Errore','Inserire almeno un sito elettrico o un sito gas');
        this.loading=false;
        return;
      }


      let datasend = this.form;
      datasend['eva_payload'] = JSON.stringify(editorData);
      // console.log(datasend);
      // if(!this.change_pwd||!this.form.new_password){
      //   delete(datasend['new_password']);
      // }
      ApiService.put('bot-management/jobs/'+this.currentEditId(),datasend)
          .then(({data}) => {
            Vue.customNotifySuccess('Salvato');
            if(!resetJob) {
              this.loading = false;
              this.reload();
            }else{
              this.resetJob();
            }
            UtilService.scrollToTop();

          })
          .catch(({response}) => {
            if(response.data && response.data.errors) {
              Vue.customNotifyError('Errore salvataggio', response.data && response.data.errors ? response.data.errors.join(', ') : 'Si prega di riprovare');
            }else{
              Vue.customNotifyError('Errore salvataggio', response.data ? JSON.stringify(response.data) : 'Si prega di riprovare');
            }
            this.reload();
          });
    },
    onReset(evt) {
      this.$router.go(-1);

    },
    initialize () {
      this.reload();
    },
    resetJob(){
      ApiService.put('bot-management/jobs/reset/'+this.currentEditId())
          .then(({data}) => {
            Vue.customNotifySuccess('Resettato');
            // this.form = data;

            // this.loading=false;
            this.reload();
          })
          .catch(({response}) => {
            //context.commit(SET_ERROR, response.data.errors);
            Vue.customNotifyError('Errore reset', response.data ? JSON.stringify(response.data) : 'Si prega di riprovare');
            this.reload();
          });
    },
    saveAndResetJob(data){
      this.saveJob(true);
    },
    reload(){
      const that = this;
      this.loading=true;
      ApiService.get('bot-management/jobs/'+this.currentEditId())
          .then(({data}) => {

            this.form = data;

            if(this.editor) {
              this.editor.setValue(JSON.parse(data.eva_payload));
            }
            // console.log('e',data.eva_status);

            // this.formUpdateStatus.status = this.statuseva.find((x)=>x.value==data.eva_status);
            this.formUpdateStatus.status = data.eva_status;
            this.formUpdateStatus.error = data.eva_error;
            // console.log(this.formUpdateStatus);

            this.loading=false;
          })
          .catch(error => {
            console.error(error);
            //context.commit(SET_ERROR, response.data.errors);
          });
    },
  }
};
</script>
